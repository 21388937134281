import React, {memo} from 'react';
import styled from 'styled-components';

import PageWrapper from '../containers/PageWrapper';
import {Trans} from 'gatsby-plugin-react-i18next';

const Page404 = () => (
  <PageWrapper title="404 - Page not found" description="Page not found" urlPath="404" keywords={['coderylabs', '404', 'page not found']}>
    <Container><Trans>404 not found</Trans></Container>
  </PageWrapper>
);

const Container = styled.div`
  width: 100%;
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

export default memo(Page404);
